import React from "react"
import Layout from "../components/Layout"
import notFoundImage from "../images/not-found-image.svg"

export default () => {
  return (
    <Layout>
      <main className="max-w-4xl flex-grow px-6 lg:px-0 md:mx-auto flex flex-col">
        <img className="p-4" src={notFoundImage} alt="Page not found" />
      </main>
    </Layout>
  )
}
